/**
 * Created by zm on 2017/5/19.
 * 工单的路由
 */

export const repair = [
  /**
   * 工单列表
   */
  // {
  //   path: 'list',
  //   name: 'Menu_OrderList',
  //   meta: {
  //     title: '工单列表'
  //   },
  //   index: '0',
  //   isNavMenu: true,
  //   component: function (resolve) {
  //     require(['../views/service/repair/order/list.vue'], resolve)
  //   }
  // },


  /**
   * 创建反馈内容
   */
  {
    path: 'feedback',
    meta: {
      title: '反馈内容'
    },
    component: function (resolve) {
      require(['../views/service/repair/order/feedback.vue'], resolve)
    }
  },

  /**
   * 保修单详情
   */
  // {
  //   path: 'detail',
  //   meta: {
  //     title: '报修单详情'
  //   },
  //   index: '1',
  //   component: function (resolve) {
  //     require(['../views/service/repair/order/detail.vue'], resolve)
  //   }
  // },

  /**
   * excile 表格
   */
  // {
  //   path: 'table',
  //   meta: {
  //     title: '工单详情表格'
  //   },
  //   index: '1',
  //   component: function (resolve) {
  //     require(['../views/service/repair/order/table.vue'], resolve)
  //   }
  // },
  /**
   * 工单详情
   */
  // {
  //   path: 'report/info',
  //   meta: {
  //     title: '工单详情'
  //   },
  //   component: function (resolve) {
  //     require(['../views/service/repair/application/info.vue'], resolve)
  //   }
  // },

  /**
   * 申请审核
   */
  // {
  //   path: 'report/list',
  //   name: 'Menu_ApplicationList',
  //   meta: {
  //     title: '申请审核'
  //   },
  //   isNavMenu: true,
  //   component: function (resolve) {
  //     require(['../views/service/repair/application/rlist.vue'], resolve)
  //   }
  // },

  /**
   * 报修申请
   */
  // {
  //   path: 'report/createOrder',
  //   name: 'Menu_OrderApplication',
  //   meta: {
  //     title: '报修申请'
  //   },
  //   isNavMenu: true,
  //   component: function (resolve) {
  //     require(['../views/service/repair/application/createorder.vue'], resolve)
  //   }
  // },

];

