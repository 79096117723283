/**
 * Created by xinsw on 2017/3/24.
 */

export const user = [

  /**
   * 用户列表
   */
  {
    path: 'list',
    name: 'UserManager_UserList',
    meta: {
      title: '用户列表'
    },
    index: '0',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/user/list.vue'], resolve)
    }
  },
  {
    path: 'list/info/:id',
    meta: {
      title: '用户详情'
    },
    component: function (resolve) {
      require(['../views/user/info.vue'], resolve)
    }
  },

  {
    path: 'list/info/myhome/:id',
    meta: {
      title: '我的家修改'
    },
    component: function (resolve) {
      require(['../views/user/myhome.vue'], resolve)
    }
  },

  /**
   * 角色详情
   */
  // {  后面考虑用 path-to-regexp匹配模式
  // path: 'detail',
  // meta: {
  //   title: '用户详情'
  // },
  // index: '1',
  // component: function (resolve) {
  //   require(['../views/user/detail.vue'], resolve)
  // }
  // },


  /**
   * 角色列表
   */
  {
    path: 'role/list',
    name: 'UserManager_RoleList',
    meta: {
      title: '角色列表'
    },
    index: '2',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/user/role/list.vue'], resolve)
    }
  },
  /**
   * 角色详情
   */
  { //后面考虑用 path-to-regexp匹配模式
    path: 'role/detail',
    meta: {
      title: '角色详情'
    },
    index: '3',
    component: function (resolve) {
      require(['../views/user/role/detail.vue'], resolve)
    }
  },
  //,
  /**
   * 权限列表
   */
  {
    path: 'permission/list',
    name: 'UserManager_PermissionList',
    meta: {
      title: '权限列表'
    },
    index: '4',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/user/role/plist.vue'], resolve)
    }
  }
];