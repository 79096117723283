/**
 * Created by xinsw on 2017/3/24.
 * 运维服务模块的路由
 */
//工单路由模块
import {repair} from "./repair";

export const service = [
  /**
   * 运维信息库列表
   */
  {
    path: 'repair',
    meta: {
      title: '运维信息库'
    },
    index: '0',
    isTwoNavMenu: true,
    hasSubMenu: true,
    component: {
      template: `<router-view></router-view>`
    },
    children: repair
  },


  /**
   * 运维知识库列表
   */
  {

    path: 'type/list',
    name: 'Menu_KnowledgeBase',
    path: 'infobase/list',
    meta: {
      title: '运维知识库'
    },
    index: '1',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/service/infobase/list.vue'], resolve)
    }
  },


    /**
     * 运维知识库列表
     */
    {
      path: 'infobase/info',
      meta: {
        title: '运维详情'
      },
      component: function (resolve) {
        require(['../views/service/infobase/info.vue'], resolve)
      }
    },

    /**
     * 新增运维详情
     */
    {
      path: 'infobase/addinfo',
      meta: {
        title: '新增运维知识'
      },
      component: function (resolve) {
        require(['../views/service/infobase/addInfo.vue'], resolve)
      }
    },

  /**
   * 故障等级管理
   */
  {
    path: 'fault/list',
    name: 'Menu_Category',
    meta: {
      title: '故障分类'
    },
    index: '3',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/service/fault/parentFatList.vue'], resolve)
    }
  },

    /**
   * 子故障
   */
  {  //后面考虑用 path-to-regexp匹配模式
    path: 'fault/info',
    meta: {
      title: '二级故障'
    },
    index: '4',
    component: function (resolve) {
      require(['../views/service/fault/childFatList.vue'], resolve)
    }
  },

];
