/**
 * Created by xinsw on 2017/1/14.
 */

// 服务器地址
// export const API_ROOT = 'http://mwind.inodes.cn/platform';
export const MQTT_ROOT = 'wss://message.ilikeit-runner.com/mqtt';
export const API_ROOT = '/platform';
 
// 设置请求的超时时间   单位毫秒
export const REQUEST_TIMEOUT = 30000;

// image_server 图片服务器地址
const debug = process.env.NODE_ENV !== 'production';

// export const IMAGE_PATH = debug?'http://localhost:8080/static/':'http://120.76.222.96:9090/static/';
export const IMAGE_PATH = '/static/'

//一页显示的条目
export const PAGE_SIZE = Math.floor((window.innerHeight - 240) / 42) - 1 || 10;

//工单状态映射关系
export const STATUS_TABLE = {
  // 'Pending': '待审批',
  // 'UnApproved': '未通过',
  // 'LevelTwoApprove': '二级审批',
  'Pending': '待分配',
  'Handling': '处理中',
  'Done': '已完成',
  'Closed': '已关闭',
  

};
//报修列表状态映射关系
export const STATUS_ORDERAP = {
  'Pending': '待受理',
  'Accept': '待一级审批',
  'LevelTwoApprove': '待二级审批',
  'Closed': '已关闭'
};
