//更新用户信息
export const REFRESH_USER_INFO = 'REFRESH_USER_INFO';

//更新角色列表
export const REFRESH_ROLELIST = 'REFRESH_ROLELIST';

//更新分类列表
export const REFRESH_SORTS = 'REFRESH_SORTS';

//更新进制的状态
export const REFRESH_SYSTEM = 'REFRESH_SYSTEM';

