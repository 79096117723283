/**
 * date: 2019/4/14
 * author: jorken
 * description: create mqtt client
 */
import _ from 'lodash';
import mqtt from 'mqtt';
import moment from 'moment';
import { MQTT_ROOT } from "../../../src/config"; 

const USERNAME = 'admin';
const PASSWORD = 'admin';
const CLIENTID = 'ilikeit' + Math.random().toString(16).substr(2, 8);

const mqttOption = {
  clientId: CLIENTID,
  username: USERNAME,
  password: PASSWORD,
  reconnectPeriod: 10000,
  clean: true
};

function parseLodash(str){
  return _.attempt(JSON.parse.bind(null, str));
}

export class mqttClient {
  constructor() {
    this.client = mqtt.connect(MQTT_ROOT, mqttOption);
    this.client.on('connect', (connack) => {
      console.log('mqtt connect success');
      this.client.on('message', (topic, message, packet) => {
        // console.log(topic + ' time:' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        const msgJson = message.toString();
        // console.log(msgJson);

        if (msgJson.includes('{') && msgJson.includes('}')) {
          const startIndex = msgJson.indexOf('{');
          const endIndex = msgJson.indexOf('}') + 1;
          const newMsg = msgJson.substring(startIndex, endIndex);
          const messageObj = parseLodash(newMsg);
          window.app.$emit('mqttMsg', { topic, message: messageObj, uint8Array: message });
        }
      });
    });
  }
  subscribe(topic, options={ qos: 2 }, callback) {
    this.client.subscribe(topic, options, (err, granted) => {
      callback && callback(err, granted);
    });
    console.log('subscribe ' + topic);
  }
  publish(topic, message, options={ qos: 2 }, callback) {
    this.client.publish(topic, message, options);
    callback && callback();
    console.log('publish ' + topic + message + ' time:' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  }
  unsubscribe(topic) {
    this.client.unsubscribe(topic);
    console.log('unsubscribe ' + topic);
  }
}
