/**
 * Created by xinsw on 2017/3/24.
 */

//用户中心路由模块
import { ucenter } from "./ucenter";
//用户管理路由模块
import { user } from "./user";
//大屏管理路由模块
import { bscreen } from "./screen";
//分组管理
import { group } from "./group";
//滤网管理
import { filter } from "./filter";
//我的设备路由模块
import { customer } from "./customer";
//设备管理路由模块
import { device } from "./device";
//系统设置
import { setting } from "./setting";
//运维服务
import { service } from "./service";
//统计模块
import { stat } from "./stat";
//报警模块
import { alert } from "./alert";


/**
 * isNavMenu的时候表示该route会出现在navbar一级菜单栏
 */
const pages = [

    /**
     * 发现/ 重定向到/index
     */
    {
        path: '/',
        meta: {
            title: '新风'
        },
        // redirect: '/index'
        redirect: '/index'
    },
    /**
     * 首页
     */
    {
        path: '/index',
        icon: 'fa-tv',
        name: 'Overview',
        meta: {

            title: '概览'
        },
        isNavMenu: true,
        component: function(resolve) {
            require(['../views/index.vue'], resolve)
        }
    },
    /**
     * 设备统计
     */
    // {
    //   path: '/dvcStat',
    //   icon: 'fa-line-chart',
    //   name: 'devcieStatics',
    //   meta: {
    //       title: '设备统计'
    //   },
    //   isNavMenu: true,
    //   component: function(resolve) {
    //       require(['../views/dvcStat/index.vue'], resolve)
    //   }
    // },
    /**
     * 设备管理
     */
    {
        path: '/device',
        icon: 'fa-cubes',
        name: 'DeviceManager',
        meta: {
            title: '设备管理'
        },
        isNavMenu: true,
        hasSubMenu: true,
        component: {
            template: `<router-view></router-view>`
        },
        children: device
    },



    /**
     * 客户管理
     */
    {
        path: '/customer',
        name: 'CustomerManager',
        meta: {
            title: '客户管理'
        },
        icon: 'fa-group',
        isNavMenu: true,
        hasSubMenu: true,
        component: {
            template: `<router-view></router-view>`
        },
        children: customer
    },

    /**
     * 壁纸管理
     */
    {
      path: '/wallpaper',
      icon: 'fa-group',
      // name加完权限后记得改
      name: 'HouseBackground',
      meta: {
        title: '壁纸管理'
      },
      isNavMenu: true,
      component: function(resolve) {
          require(['../views/wallpaper/index.vue'], resolve)
      }
    },

    /**
     * 报警中心
     */
    {
        path: '/alert',
        icon: 'fa-bell',
        name: '',
        meta: {
            title: '报警中心'
        },
        isNavMenu: true,
        hasSubMenu: true,
        component: {
            template: `<router-view></router-view>`
        },
        children: alert
    },
    /**
     * user
     */
    {
        path: '/user',
        name: '',
        meta: {
            title: '用户管理'
        },
        icon: 'fa-vcard',
        isNavMenu: true,
        hasSubMenu: true,
        component: {
            template: `<router-view></router-view>`
        },
        children: user
    },
    /*
          大屏管理
     * */
    // {
    //     path: '/screen',
    //     name: '',
    //     meta: {
    //         title: '大屏管理'
    //     },
    //     icon: 'fa-desktop',
    //     isNavMenu: true,
    //     hasSubMenu: true,
    //     component: {
    //         template: `<router-view></router-view>`
    //     },
    //     children: bscreen
    // },
    /*
          分组管理
     * */
    // {
    //     path: '/group',
    //     name: '',
    //     meta: {
    //         title: '分组管理'
    //     },
    //     icon: 'fa-users',
    //     isNavMenu: true,
    //     hasSubMenu: true,
    //     component: {
    //         template: `<router-view></router-view>`
    //     },
    //     children: group
    // },
    /*
         滤网管理
     * */
    // {
    //     path: '/filter',
    //     name: '',
    //     meta: {
    //         title: '滤网管理'
    //     },
    //     icon: 'fa-filter',
    //     isNavMenu: true,
    //     hasSubMenu: true,
    //     component: {
    //         template: `<router-view></router-view>`
    //     },
    //     children: filter
    // },
    /**
     * 用户反馈
     */
    // {
    //     path: '/feedback',
    //     icon: 'fa-commenting',
    //     name: 'Feedback',
    //     meta: {
    //         title: '用户反馈'
    //     },
    //     isNavMenu: true,
    //     component: function(resolve) {
    //         require(['../views/feedback/list.vue'], resolve)
    //     }
    // },
    /**
     * 固件更新管理
     */
    {
        path: '/update',
        icon: 'fa-cloud-upload',
        name: 'FirmwareVersion',
        meta: {
            title: '固件更新管理'
        },
        isNavMenu: true,
        component: function(resolve) {
            require(['../views/update/list.vue'], resolve)
        }
    },
    /**
     * 系统日志记录
     */
    {
        path: '/systemlog',
        icon: 'fa-fax',
        name: 'SystemLog',
        meta: {
            title: '系统日志记录'
        },
        isNavMenu: true,
        component: function(resolve) {
            require(['../views/system/list.vue'], resolve)
        }
    },

    /**
     * 运维服务
     */
    // {
    //     icon: 'fa-bar-chart',
    //     isNavMenu: true,
    //     hasSubMenu: true,
    //     path: '/service',
    //     name: '',
    //     meta: {
    //         title: '统计中心'
    //     },
    //     component: {
    //         template: `<router-view></router-view>`
    //     },
    //     children: stat
    // },




    
    /**
     * 用户中心
     */
    {
        path: '/ucenter',
        component: function(resolve) {
            require(['../views/ucenter/index.vue'], resolve)
        },
        children: ucenter
    },

    /**
     * 用户反馈详情
     */
    {
        path: '/feedback/:id',
        name: '',
        meta: {
            title: '用户反馈详情'
        },
        component: function(resolve) {
            require(['../views/feedback/info.vue'], resolve)
        }
    },


    /**
     * 登录
     */
    {
        path: '/login',
        meta: {
            title: '欢迎登录'
        },
        // component: require("../views/ucenter/login.vue")
        component: function(resolve) {
            require(['../views/ucenter/login.vue'], resolve)
        },
    },

    /**
     * 忘记密码
     */
    {
        path: '/forgetpwd',
        meta: {
            title: '忘记密码'
        },
        component: function(resolve) {
            require(['../views/ucenter/forgetpwd.vue'], resolve)
        }
    },

    /**
     * 系统错误界面
     */
    {
        path: '/error',
        component: function(resolve) {
            require(['../components/errors/error.vue'], resolve)
        }
    },


    // not found handler 404
    {
        path: '*',
        component: function(resolve) {
            require(['../components/errors/not-fount.vue'], resolve)
        }
    }
];

/**
 * App.vue是系统全局的承载vue，包含了header和footer等等，所以只切换content内容即可，放在children中
 * @type {[*]}
 */
export const routes = [{
    path: '/',
    component: function(resolve) {
        require(['../App.vue'], resolve)
    },
    children: pages
}];
