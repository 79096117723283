import * as types from "../mutation-types";

// initial state
const state = {
  user: {},
  roles: [],
};

// getters
const getters = {
  userInfo: state => state.user,
  roles: state => state.roles
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.REFRESH_USER_INFO] (state, user) {
    state.user = user;
  },
  [types.REFRESH_ROLELIST] (state, roles) {
    state.roles = roles;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
