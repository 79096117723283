/**
 * Created by xinsw on 2017/3/24.
 */

export const ucenter = [
  {
    path: 'userinfo',
    meta: {
      title: '个人设置'
    },
    index: '0',
    component: function (resolve) {
      require(['../views/ucenter/userinfo.vue'], resolve)
    }
  },
  {
    path: 'editpwd',
    meta: {
      title: '修改密码'
    },
    index: '1',
    component: function (resolve) {
      require(['../views/ucenter/editpwd.vue'], resolve)
    }
  },
  {
    path: 'headerimage',
    meta: {
      title: '头像设置'
    },
    index: '2',
    component: function (resolve) {
      require(['../views/ucenter/headerimage.vue'], resolve)
    }
  }

];

