/**
 * Created by xinsw on 2017/3/24.
 */

export const customer = [
  /**
   * 客户列表
   */
  {
    path: 'list',
    name: 'CustomerManager_customerList',
    meta: {
      title: '客户信息'
    },
    index: '0',
    isNavMenu: false,
    component: function (resolve) {
      require(['../views/customer/list.vue'], resolve)
    }
  },

  /**
   * 客户新增
   */
  {
    path: 'detail',
    name: 'CustomerManager_customerList',
    meta: {
      title: '客户信息'
    },
    index: '0',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/customer/detail.vue'], resolve)
    }
  },

  /**
   * 公众号列表
   */
  // {
  //   path: 'Publicnumber/',
  //   name: 'CustomerManager_PumblicList',
  //   meta: {
  //     title: '公众号列表'
  //   },
  //   index: '0',
  //   isNavMenu: true,
  //   component: function (resolve) {
  //     require(['../views/customer/publicNumber/list.vue'], resolve)
  //   }
  // },
  /**
   * 公司介绍页面列表
   */
  // {
  //   path: 'company/',
  //   name: 'CustomerManager_Company',
  //   meta: {
  //     title: '公司介绍页面列表'
  //   },
  //   index: '0',
  //   isNavMenu: true,
  //   component: function (resolve) {
  //     require(['../views/customer/company/info.vue'], resolve)
  //   }
  // },    
  
  {
    path: 'list/:id',
    meta: {
      title: '客户详情'
    },
    index: '1',
    component: function (resolve) {
      require(['../views/customer/info.vue'], resolve)
    }
  },
  {
    path: 'Publicnumber/:id',
    meta: {
      title: '公众号详情'
    },
    index: '1',
    component: function (resolve) {
      require(['../views/customer/publicNumber/info.vue'], resolve)
    }
  },
  {
    path: 'product/:id',
    meta: {
      title: '公众号产品'
    },
    //index: '1',
    component: function (resolve) {
      require(['../views/customer/publicNumber/product.vue'], resolve)
    }
  },
  {
    path: 'customer-detail/:id',
    meta: {
      title: '公众号'
    },
    // index: '1',
    component: function (resolve) {
      require(['../views/customer/publicNumber/detail.vue'], resolve)
    }
  },
  {
    path: 'company/:id',
    meta: {
      title: '公司介绍页面详情'
    },
    index: '1',
    component: function (resolve) {
      require(['../views/customer/company/info.vue'], resolve)
    }
  },
  /**
   * 客户新增
   */
  {
    path: 'detail/:id',
    meta: {
      title: '客户新增或修改'
    },
    component: function (resolve) {
      require(['../views/customer/detail.vue'], resolve)
    }
  }
  

];

