/**
 * Created by xinsw on 2017/3/24.
 */

export const device = [
  /**
   * 设备列表
   */
  {
    path: 'list',
    name: 'DeviceList',
    meta: {
      title: '活跃设备'
    },
    index: '0',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/list.vue'], resolve)
    }
  },

  /**
   * 授权列表
   */
  {
    path: 'authorize',
    name: 'deviceAuthorizeRecord',
    meta: {
      title: '设备授权'
    },
    index: '1',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/authorize/list.vue'], resolve)
    }
  },

  /**
   * 品牌商产品型号列表
   */
  {
    path: 'pkind',
    name: 'productTypeRecord_select',
    meta: {
      title: '品牌型号'
    },
    index: '3',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/kind/plist.vue'], resolve)
    }
  },
  /**
   * 产品类型列表
   */
  {
    path: 'kind',
    name: 'DeviceManager_ProductType',
    meta: {
      title: '设备型号'
    },
    index: '2',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/kind/list.vue'], resolve)
    },
    // children: ''
  },


  /**
   * 滤芯寿命计算规则列表
   */

  {
    path: 'life',
    name: 'surplusManage',
    meta: {
      title: '滤芯寿命'
    },
    index: '4',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/surplus/list.vue'], resolve)
    }
  },
  /**
   * 采集数据的类型列表
   */
  {
    path: 'data',
    name: 'DeviceManager_DataStreamType',
    meta: {
      title: '数据点管理'
    },
    index: '6',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/device/data/list.vue'], resolve)
    }
  },

  /**
   * 产品型号新增
   */
  {
    path: 'kind/detail',
    meta: {
      title: '产品型号新增'
    },
    component: function (resolve) {
      require(['../views/device/kind/detail.vue'], resolve)
    },
  },

  /**
   * 产品型号修改
   */
  {
    path: 'kind/detail/:id',
    meta: {
      title: '产品型号修改'
    },
    component: function (resolve) {
      require(['../views/device/kind/detail.vue'], resolve)
    },
  },

  {
    path: 'brand/info/:id',
    meta: {
      title: '品牌详情'
    },
    index: '4',
    component: function (resolve) {
      require(['../views/device/kind/pinfo.vue'], resolve)
    }
  },
  /**
   * 品牌修改
   */
  {
    path: 'brand/detail/:id',
    meta: {
      title: '品牌修改'
    },
    component: function (resolve) {
      require(['../views/device/kind/pdetail.vue'], resolve)
    }
  },
  /**
   * 品牌新增
   */
  {
    path: 'brand/detail',
    meta: {
      title: '品牌新增'
    },
    component: function (resolve) {
      require(['../views/device/kind/pdetail.vue'], resolve)
    }
  },
  /**
   * 产品类型列表
   */
  {
    path: 'info/:id',
    meta: {
      title: '修改设备'
    },
    index: '4',
    component: function (resolve) {
      require(['../views/device/detail.vue'], resolve)
    }
  },

  /**
   * 设备日志
   */
  {
    path: 'log/:id',
    meta: {
      title: '设备操作日志'
    },
    component: function (resolve) {
      require(['../views/device/dvclog.vue'], resolve)
    }
  },










];
