import Vue from "vue";
import VueRouter from "vue-router";
import VueTouch from "./utils/vue-plugin/vue-touch";
import Element, {Notification} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import store from "./store";
import moment from 'moment';
import lazyload from "vue-lazyload";
import toolJs from "./utils/tools/tools";
import defaultImg from "./assets/images/defaultImg.jpg";
import {routes} from "./route";
import {API_ROOT} from './config';
import {mqttClient} from './utils/mqtt/mqtt';

// mint-ui
Vue.use(Element);

// install vue-touch
Vue.use(VueTouch);

// install router
Vue.use(VueRouter);

// install lazyload
Vue.use(lazyload, {
  error: defaultImg,
  loading: defaultImg,
  try: 3 // default 1
});

Vue.prototype.$mqtt = new mqttClient();
Vue.prototype.$moment = moment;

const router = new VueRouter({
  hashbang: true,
  base: '/',
  //history: true,
  //切换页面的滚动行为
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes
});

const app = new Vue({
  store,
  router
});

// 注册一个全局的after钩子
router.afterEach(route => {
  if (route.meta.title) {
    document.title = route.meta.title;
    if (toolJs.getBrowserInfo().ios) {
      let i = document.createElement('iframe');
      i.src = './static/favicon.ico';
      i.style.display = 'none';
      i.onload = function () {
        setTimeout(function () {
          i.remove();
        }, 9);
      };
      document.body.appendChild(i);
    }
  }
});

window.router = router;

let paceOptions = {
  document: true, // disabled
  //eventLag: true,
  restartOnPushState: true,
  restartOnRequestAfter: true,
  ajax: {
    trackMethods: ['POST', 'GET', 'DELETE', 'OPTIONS'],
    ignoreURLs: ['device/lastValue/byDevice']
  },
};

if (Pace) {
  Pace.start(paceOptions);
  window.Pace = Pace;//pace 设置成全局的
}

//console.log(window.Pace)
app.$mount('#app');
window.app = app;
