/**
 * Created by xinsw on 2017/3/24.
 */

export const alert = [
  /**
   * 报警列表
   */
  {
    path: 'list',
    name: 'AlertManager',
    meta: {
      title: '报警列表'
    },
    index: '0',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/alarm/list.vue'], resolve)
    }
  },
  {
    path: 'config',
    name: 'Alert_Config',
    meta: {
      title: '报警配置'
    },
    index: '1',
    isNavMenu: true,
    component: function (resolve) {
      require(['../views/alarm/config.vue'], resolve)
    }
  }
];

