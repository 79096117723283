import * as types from "../mutation-types";

// initial state
const state = {
  sorts: {},
  switchSystem: 0
};

// getters
const getters = {
  sorts: state => state.sorts,
  switchSystem: state => state.switchSystem
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.REFRESH_SORTS] (state, sorts) {
    state.sorts = sorts;
  },
  [types.REFRESH_SYSTEM] (state, systemIndex) {
    state.switchSystem = systemIndex;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
