import * as types from "./mutation-types";

/**
 * 更新用户的信息
 * @param commit
 * @param user
 */
export const refreshUserInfo = ({commit}, user) => {
  commit(types.REFRESH_USER_INFO, user)
};

/**
 * 刷新角色列表
 * @param commit
 * @param roles Array
 */
export const refreshRoles = ({commit}, roles) => {
  commit(types.REFRESH_ROLELIST, roles)
};

/**
 * 刷新分类列表
 * @param commit
 * @param roles Array
 */
export const refreshSorts = ({commit}, sorts) => {
  commit(types.REFRESH_SORTS, sorts)
}

/**
 * 刷新分类列表
 * @param commit
 * @param roles Number
 */
export const refreshSystem = ({commit}, systemIndex) => {
  commit(types.REFRESH_SYSTEM, systemIndex)
}

